import React, { FC, useEffect, useState } from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import loadable from '@loadable/component';
import classNames from 'classnames';

import Image from 'common/Image';
import Typography from 'common/Typography';
import Button from 'components/Button';

import { playerConfig } from './config';

import { YoutubeVideoProps } from './models.d';

import './YoutubeVideo.scss';

const Youtube = loadable(() => import('react-youtube'));

const YoutubeVideo: FC<YoutubeVideoProps> = ({ className, video, isChanged }) => {
  const { title, image, url } = video;
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const youtubeVideoClasses = classNames('youtube-video', className);

  const videoId = url.replace(/.*watch\?v=/i, '');

  const showVideo = (): void => {
    if (videoId) {
      setPlayVideo(true);
    }
  };

  useEffect(() => {
    setPlayVideo(false);
  }, [isChanged]);

  return (
    <div className={youtubeVideoClasses} data-testid="youtube-video-item">
      <div className="youtube-video__container">
        {playVideo ? (
          <Youtube
            data-testid="youtube-frame"
            aria-label={title}
            videoId={videoId}
            opts={playerConfig}
            className="youtube-video__frame"
          />
        ) : (
          <Button
            ui={{
              variant: 'image',
              typography: { color: 'white' },
            }}
            onClick={showVideo}
            className="youtube-video__thumbnail"
          >
            <div className="youtube-video__wrapper">
              <Image {...image.imageStructure} className="youtube-video__image" />
            </div>
            <Icon icon={faPlay} size="xs" className="youtube-video__icon" />
            {title ? (
              <div className="youtube-video__title">
                <Typography
                  size={{ base: 12, md: 26 }}
                  padding={{ left: { base: 'sm', md: 'xxl' } }}
                  color="sherwood-green"
                  weight="bold"
                >
                  {title}
                </Typography>
              </div>
            ) : null}
          </Button>
        )}
      </div>
    </div>
  );
};

export default YoutubeVideo;
