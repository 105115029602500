import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import { SliderVariant } from 'components/Slider';
import CardListing from 'containers/CardListing';
import Carousel from 'containers/Carousel';
import VideoSection from 'containers/VideoSection';

import sortArr from 'utils/sortArr';

import './OurBrandsPage.scss';

const OurBrandsPage: FC<OurBrandsPageTypes.OurBrandsProps> = ({
  data: {
    ourBrands: { urls, seo, banner, twoColumnsBanner, videoSection, articleCarousel },
    allOurBrandsDetail: { ourBrandsDetailNodes },
    allArticleDetail: { articlesList },
  },
}) => {
  const { title, keywords, description } = seo;
  const { ARTICLES } = SliderVariant;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} className="our-brands-page__banner--hero-low" />
      <div className="our-brands-page__listing">
        <CardListing cards={sortArr(ourBrandsDetailNodes, 'brandsCardsOrder')} />
      </div>
      <Banner
        {...twoColumnsBanner}
        banerColor="white"
        className="our-brands-page__banner--two-sided"
      />
      <VideoSection {...{ videoSection }} />
      {articlesList.length && articleCarousel ? (
        <Carousel
          carouselItems={sortArr(articlesList, 'articleCardOrder')}
          carouselVariant={ARTICLES}
          carousel={articleCarousel}
          className="our-brands-page__carousel"
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $articlesUrls: [String]) {
    ourBrands(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      twoColumnsBanner {
        ...BannerFragment
      }
      videoSection {
        ...VideoSectionFragment
      }
      articleCarousel {
        ...CarouselFragment
      }
    }
    allOurBrandsDetail {
      ourBrandsDetailNodes: nodes {
        ...BrandCardFragment
        brandsCardsOrder
      }
    }
    allArticleDetail(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }
  }
`;

export default OurBrandsPage;
