import { VideoMock } from 'components/YoutubeVideo';

export const VideoSectionDefaultClassesMock = ['video-section', 'test-class'];

export const VideoSectionDefaultPropsMock = {
  className: 'test-class',
  videoSection: {
    heading: 'Heading',
    videos: [
      VideoMock,
      {
        ...VideoMock,
        title: 'second-video-title',
        url: 'https://www.youtube.com/watch?v=EngW7tLk6R8',
      },
    ],
  },
};

export const VideoSectionChildrenMock = 'test content';
