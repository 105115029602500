import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { FADEIN } from 'framers/FadeIn';

import Typography from 'common/Typography';
import Slider, { SliderVariant } from 'components/Slider';
import YoutubeVideo from 'components/YoutubeVideo';

import { VideoSectionProps } from './models.d';

import './VideoSection.scss';

const { VIDEO } = SliderVariant;
const { DOWN } = FADEIN;

const VideoSection: FC<VideoSectionProps> = ({ className, videoSection }) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const { heading, videos } = videoSection;

  const videoSectionClasses = classNames('video-section', className);

  const slidesArray = videos?.map((video, id) => ({
    id,
    component: <YoutubeVideo {...{ video, isChanged }} />,
  }));

  return (
    <div className={videoSectionClasses} data-testid="video-section-item">
      {heading ? (
        <div className="video-section__title">
          <Typography
            color="sherwood-green"
            size={{ base: 25, md: 36 }}
            weight="bold"
            as="h2"
            padding={{ top: 'md', bottom: 'md' }}
            animationVariant={DOWN}
          >
            {heading}
          </Typography>
        </div>
      ) : null}
      {videos.length > 1 ? (
        <Slider
          slides={slidesArray}
          variant={VIDEO}
          setIsChanged={setIsChanged}
          sliderId="videos"
        />
      ) : (
        <YoutubeVideo video={videos[0]} />
      )}
    </div>
  );
};

export default VideoSection;
