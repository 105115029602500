import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import FadeIn from 'framers/FadeIn';

import { Container } from 'layout';
import Card, { CardType } from 'components/Card';

import sortArr from 'utils/sortArr';

import { CardListingProps } from './models.d';

import './CardListing.scss';

const CardListing: FC<CardListingProps> = ({ cards }) => (
  <>
    {cards?.length ? (
      <Container>
        <FadeIn>
          <motion.div
            layout
            className="card-listing animate--child-fade"
            data-testid="card-listing"
          >
            <AnimatePresence>
              {sortArr(cards, 'articleCardOrder').map(({ id, card, tags, url }: CardType) => (
                <motion.div
                  key={id}
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <Card {...{ ...card, tags, url }} showBadge />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </FadeIn>
      </Container>
    ) : null}
  </>
);
export default CardListing;
